import { Dashboard } from "../types/Dashboard";
import { DashboardRaw } from "../types/DashboardRaw";

export const parseDashboard = (dashboard: DashboardRaw): Dashboard => {
  return {
    dashboardId: dashboard.dashboard_id,
    dashboardUrl: dashboard.dashboard_url,
    isActive: dashboard.is_active,
    reference: dashboard.reference * 1000,
    reportId: dashboard.report_id,
    tenantCode: dashboard.tenant_code,
    title: dashboard.title,
    type: dashboard.type,
  };
};
