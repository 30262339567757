import { ObjectOf } from "../../common/types/ObjectOf";
import Kpi from "../types/Kpi";
import { ProviderNodeData } from "../types/NodeData";
import { getProviderNodeKpisData } from "./nodeContents";

export type SelectKpiOptions = ObjectOf<{ id: string; label: string; value: string; valueType: string }>;

/** Retorna um dicionário de opções de Kpis para o nó de provider selecionado.
 * @param providerNodeData Dados do nó de provider.
 * @param simulatorKpis Array contendo os Kpis do simulador do nó de provider selecionado.
 * @returns
 */
export const getKpisOptions = (providerNodeData: ProviderNodeData, simulatorKpis?: Array<Kpi>): SelectKpiOptions => {
  const providerNodeKpiData = getProviderNodeKpisData(providerNodeData);

  const baseKpisOptions =
    providerNodeData.options?.kpis.reduce(
      (acc, kpi) => ({
        ...acc,
        [kpi.kpi]: {
          id: kpi.kpi,
          label: providerNodeKpiData ? providerNodeKpiData[kpi.kpi] : "undefined",
          value: kpi.kpi,
          valueType: kpi.value_type,
        },
      }),
      {} as SelectKpiOptions
    ) || ({} as SelectKpiOptions);

  const simulatorKpisOptions =
    simulatorKpis?.reduce(
      (acc, kpi) => ({
        ...acc,
        [kpi.kpi]: {
          id: kpi.kpi,
          label: kpi.label || "no-label",
          value: kpi.kpi,
          valueType: kpi.value_type,
        },
      }),
      {} as SelectKpiOptions
    ) || ({} as SelectKpiOptions);

  return { ...baseKpisOptions, ...simulatorKpisOptions };
};
