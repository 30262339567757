import { ObjectOf } from "../../common/types/ObjectOf";
import NodeData, { ProviderNodeData } from "../types/NodeData";

const getNodeContents = (nodeData: NodeData) => {
  if (nodeData.type === "action") {
    switch (nodeData.action) {
      case "jump":
        return {
          color: "rgba(238, 235, 255, 1)",
          subtitle: "Changes the product flow",
          title: "Jump",
        };
      case "match":
        return {
          color: "rgba(229, 254, 229, 1)",
          subtitle: "Usuário deu match com o produto",
          title: "Aprovado",
        };
      case "unmatch":
        return {
          color: "rgba(254, 223, 216, 1)",
          subtitle: "Usuário não deu match com o produto",
          title: "Reprovado",
        };
      case "moderation":
        return {
          color: "rgba(254, 250, 216, 1)",
          subtitle: "É hora de decisão humana",
          title: "Moderação",
        };
      case "warning":
        return {
          color: "rgba(255, 246, 218, 1)",
          subtitle: "Exibir a mensagem:",
          title: "Atenção",
        };
    }
  } else if (nodeData.type === "providers" || nodeData.type === "global" || !nodeData.type) {
    switch (nodeData.provider) {
      case "serasa_fraud_cpf":
        return {
          description: [
            `Score Fraud provides an indicator of the likelihood of fraudulent activity associated with your target. ` +
              `Scores range from 0 (high fraud risk) to 1000 (low fraud risk)`,
            `By connecting Providers to other flow steps, you can create automated decisions based on the conditions ` +
              `you set here, enhancing security and reducing operational risks.`,
          ],
          kpis: {
            score_fraude: "Score de Fraude",
          },
          subtitle: "Score de Fraude",
          title: "Score de Fraude - CPF",
        };
      case "serasa_fraud_cnpj":
        return {
          description: [
            `Score Fraud provides an indicator of the likelihood of fraudulent activity associated with your target. ` +
              `Scores range from 0 (high fraud risk) to 1000 (low fraud risk)`,
            `By connecting Providers to other flow steps, you can create automated decisions based on the conditions ` +
              `you set here, enhancing security and reducing operational risks.`,
          ],
          kpis: {
            score_fraude: "Score de Fraude",
          },
          subtitle: "Score de Fraude",
          title: "Score de Fraude - CNPJ",
        };
      case "serasa_completo":
        return {
          description: [
            `O Score de Crédito fornece uma pontuação baseada
        na saúde financeira do seu alvo. 0 – Má saúde financeira
        1000 - Boa saúde financeira`,
            `Ao conectar provedores a outras etapas do fluxo, você pode criar
      decisões automatizadas com base nas condições que você definiu aqui.`,
          ],
          kpis: {
            Score: "Score de Crédito",
          },
          subtitle: "Score de Crédito",
          title: "Score de Crédito",
        };
      case "serasa_parcial":
        return {
          description: [
            `Liquid Parcial consulta agência de crédito para fornecer informações
        sobre as dívidas e protestos de seus alvos.`,
            `Ao conectar provedores a outras etapas do fluxo, você pode criar
        decisões automatizadas com base nas condições que você definiu aqui.`,
          ],
          kpis: {
            cpf_cancelled: "CPF está cancelado?",
            cpf_regular: "CPF está regular?",
            debts_sum: "Soma das restrições",
            has_debts: "Possui restrição?",
          },
          subtitle: "Débitos e protestos",
          title: "Liquid Parcial",
        };
      case "serasa_cnpj":
        return {
          description: [
            `Liquid Parcial consulta agência de crédito para fornecer informações
        sobre as dívidas e protestos de seus alvos.`,
            `Ao conectar provedores a outras etapas do fluxo, você pode criar
        decisões automatizadas com base nas condições que você definiu aqui.`,
          ],
          kpis: {
            debts_sum: "Soma das restrições",
            has_debts: "Possui restrição?",
            score: "Score de crédito",
          },
          subtitle: "Restrições e Score de crédito",
          title: "Liquid Pass - CNPJ",
        };
      case "financial_indicators":
        return {
          description: [
            `Indicador Financeiro Liquid consulta agência de crédito para fornecer informações
          sobre a capacidade de pagamento.`,
            `Ao conectar provedores a outras etapas do fluxo, você pode criar
          decisões automatizadas com base nas condições que você definiu aqui.`,
          ],
          kpis: {
            income: "Salário estimado",
            income_commitment: "Comprometimento de renda estimado",
            max_payment: "Capacidade de pagamento mensal estimada",
          },
          subtitle: "Capacidade de pagamento e renda",
          title: "Indicador Financeiro Liquid",
        };
      case "liquidpass":
        return {
          description: [
            `Inteligência de Dados Liquid faz uma pesquisa completa sobre o seu alvo
          perfil, mostrando informações sobre IPTU, parceiros, políticos
          exposição e veículos.`,
            `Ao conectar provedores a outras etapas do fluxo, você pode criar
          decisões automatizadas com base nas condições que você definiu aqui.`,
          ],
          kpis: {
            has_assistances: "Possui assistências?",
            has_partners: "Possui participações societárias?",
            has_vehicles: "Possui veículos?",
          },
          subtitle: "Sociedade, assistências, veículos...",
          title: "Inteligência de Dados Liquid",
        };
      case "liquidpass_cnpj":
        return {
          description: [
            `Inteligência de Dados Liquid faz uma pesquisa completa sobre o seu alvo
          perfil, mostrando informações sobre IPTU, parceiros, políticos
          exposição e veículos.`,
            `Ao conectar provedores a outras etapas do fluxo, você pode criar
          decisões automatizadas com base nas condições que você definiu aqui.`,
          ],
          kpis: {
            has_partners: "Possui participações societárias?",
            has_vehicles: "Possui veículos?",
            revenue_presumed: "Faturamento Presumido",
          },
          subtitle: "Sociedade e veículos...",
          title: "Inteligência de Dados Liquid - CNPJ",
        };
      case "pluggy":
        return {
          description: [
            `Open Finance Pluggy investiga a saúde financeira do seu
        alvo, mostrando informações de empréstimo e saldo, em horários diferentes
        períodos.`,
            `Conectando provedores a outras etapas de fluxo que você pode criar
        decisões automáticas com base nas condições definidas aqui.`,
          ],
          kpis: {
            estimated_income: "Renda estimada",
            loans_request_3M: "Possui empréstimos nos últimos 3 meses?",
          },
          subtitle: "Empréstimos, créditos, débitos...",
          title: "Open Finance Pluggy",
        };
      case "peer_model":
        return {
          description: [
            `Rating Liquid é baseada no risco de fazer negócios com seu alvo.`,
            `H -> Alto risco`,
            `A -> Menor risco`,
            `Conectando provedores a outras etapas de fluxo que você pode criar
        decisões automáticas com base nas condições definidas aqui.`,
          ],
          kpis: {
            rating: "Rating Liquid",
          },
          subtitle: "Classificação de Inadimplência",
          title: "Rating Liquid",
        };
      case "nuclea_income_certificate":
        return {
          description: [
            `Em parceria com a Núclea,
            permite que o processo de emissão e/ou validação de certificados HMP/HIS seja concluído aqui.`,
          ],
          kpis: {
            tipoMoradiaFaixa: "Tipo de Moradia do Certificado Núclea",
          },
          subtitle: "Emissão de Certificados HIS, HIS 2 e HMP",
          title: "Certificação de Renda Núclea",
        };
      case "open_credit":
        return {
          description: [],
          kpis: {
            active_wallet_table_v30_value: "Valor a vencer em até 30 dias",
            has_prejudice: "Possui prejuízo?",
            risk_total_value: "Risco total",
          },
          subtitle: "Empréstimos, créditos, limites...",
          title: "Open Credit",
        };
      case "register_upload":
        return {
          description: [
            `Permita o envio de documentos de um cartório específico, tanto via WhatsApp como pela plataforma,
            necessários. Este nó funciona somente junto ao nó de Registro de Compra e Venda com Alienação.`,
          ],
          kpis: {
            docs_uploaded: "Algum documento foi adicionado?",
          },
          subtitle: "Para o Registro de Compra e Venda com Alienação",
          title: "Documentos por cartório",
        };
      case "doc_signature":
        return {
          description: [
            `Os nomes que vão aparecer na assinatura do documento serão os mesmos indicados acima.
            A assinatura não é sequencial, ou seja, o documento pode ser assinado em qualquer ordem.`,
          ],
          kpis: {
            signed: "Todos assinaram?",
          },
          subtitle: "Assinatura das partes selecionadas com Certificado e-CPF",
          title: "Portal de Assinaturas e-CPF",
        };
      case "simulation":
        switch (nodeData.options?.simulation_type) {
          case "allotment":
            return {
              description: [
                `A Simulação Parametrizada Liquid simula o comprometimento de renda de cada um de seus cenários.`,
                `Ao conectar Provedores a outras etapas do fluxo, você pode criar decisões automatizadas 
                com base nas condições que você definiu aqui.`,
              ],
              kpis: {
                highlight__desired_income_approval: "Está enquadrado na condição desejada do Simulador?",
                highlight__has_approved_bank: "Está enquadrado em alguma condição do Simulador Parametrizado?",
              },
              subtitle: "Parcelas, pré-aprovação...",
              title: "Simulador Liquid Parametrizado",
            };
          case "basic":
            return {
              description: [
                `O Simulador Liquid Básico simula o compromisso de rendimento com base no valor mensal desejado
                valor do pagamento e receitas, fornecendo informações sobre o aumento de renda necessário.`,
                `Ao conectar Provedores a outras etapas do fluxo, você pode criar decisões automatizadas 
                com base nas condições que você definiu aqui.`,
              ],
              kpis: {
                income_approval: "A renda está enquadrada no pagamento mensal pretendido?",
              },
              subtitle: "Comprometimento de renda, capacidade máxima...",
              title: "Simulador Liquid Básico",
            };
          case "morar":
            return {
              description: [
                `O Simulador Liquid Morar simula o comprometimento da renda com base no valor mensal desejado
                  valor do pagamento e receitas, fornecendo informações sobre o aumento de renda necessário.`,
                `Ao conectar Provedores a outras etapas do fluxo, você pode criar decisões automatizadas 
                com base nas condições que você definiu aqui.`,
              ],
              kpis: {
                highlight__advance_payment_approved: "Está enquadrado no simulador?",
              },
              subtitle: "Parcelas, pré-aprovação...",
              title: "Simulador Liquid Morar",
            };
          case "sbpe":
            return {
              description: [
                `Simulador Liquid SBPE combina diferentes agências e uma IA para simular financiamento bancário, 
                com informações sobre parcelamento, rendimento e pré-aprovação em bancos.`,
                `Ao conectar Provedores a outras etapas do fluxo, você pode criar decisões automatizadas 
                com base nas condições que você definiu aqui.`,
              ],
              kpis: {
                highlight__has_approved_bank: "Está enquadrado no fluxo de financiamento?",
                highlight__has_approved_bank_and_building_payment_over_time:
                  "Está enquadrado no fluxo de financiamento e obra?",
                highlight__has_approved_building_payment_over_time: "Está enquadrado no fluxo de obra?",
              },
              subtitle: "Parcelas, pré-aprovação...",
              title: "Simulador Liquid SBPE",
            };
          case "simulator":
            return {
              description: [
                `Este simulador foi configurado no builder de simuladores. Lá você pode escolher as variáveis de
                entrada, as funções que serão responsáveis por trazer indicadores importantes para sua análise e
                monta um painel personalizado para visualizar os resultados.`,
              ],
              kpis: {},
              subtitle: "KPIs customizados, condições personalizadas, etc",
              title: "",
            };
        }
    }
  } else {
    return {
      color: "rgba(240, 246, 255, 1)",
      subtitle: "O fluxo de destino começa com",
      title: "Start Flow",
    };
  }
};

export const getNodeTitle = (nodeData: NodeData) => {
  return getNodeContents(nodeData)?.title || nodeData.title;
};

export const getNodeSubtitle = (nodeData: NodeData) => {
  return getNodeContents(nodeData)?.subtitle || nodeData.subtitle;
};

export const getNodeColor = (nodeData: NodeData) => {
  return getNodeContents(nodeData)?.color || "rgba(249, 249, 250, 1)";
};

export const getNodeDescription = (nodeData: NodeData) => {
  return getNodeContents(nodeData)?.description || [];
};

export const getProviderNodeKpisData = (nodeData: ProviderNodeData) => {
  return getNodeContents(nodeData)?.kpis as unknown as ObjectOf<string>;
};
