import { Chip, ChipProps } from "@mui/material";

const getPadding = (size: string | undefined) => {
  switch (size) {
    case "extra-small":
      return "6px 8px";
    case "small":
      return "8px 10px";
    case "medium":
      return "10px 12px";
    case "large":
      return "12px 14px";
    case "extra-large":
      return "14px 16px";
    default:
      return "10px 12px";
  }
};

const getFontSize = (size: string | undefined) => {
  switch (size) {
    case "extra-small":
      return "10px";
    case "small":
      return "12px";
    case "medium":
      return "14px";
    case "large":
      return "16px";
    case "extra-large":
      return "18px";
    default:
      return "14px";
  }
};

const getHeight = (size: string | undefined) => {
  switch (size) {
    case "extra-small":
      return "12px";
    case "small":
      return "16px";
    case "medium":
      return "20px";
    case "large":
      return "24px";
    case "extra-large":
      return "28px";
    default:
      return "28px";
  }
};
interface LqdChipProps extends ChipProps {
  chipbackgroundcolor?: string;
  chipcolor?: string;
  chipsize?: "extra-large" | "extra-small" | "large" | "medium" | "small";
}

export default function LqdChip(props: Omit<LqdChipProps, "size">) {
  const { chipbackgroundcolor, chipcolor, chipsize } = props;

  return (
    <Chip
      sx={{
        "& .MuiChip-label": { padding: 0 },
        backgroundColor: chipbackgroundcolor,
        border: chipbackgroundcolor ? "none" : "1px solid rgba(33, 36, 42, 1)",
        borderRadius: getHeight(chipsize),
        color: chipcolor,
        fontSize: getFontSize(chipsize),
        height: getHeight(chipsize),
        padding: getPadding(chipsize),
        width: "fit-content",
      }}
      variant="outlined"
      {...props}
    />
  );
}
