import { LqdChip, LqdLink, LqdTypography } from "@/liquid-components/src";
import { capitalizeString } from "@common/utils/capitalize";
import { stringInitials } from "@common/utils/initials";
import { truncateString } from "@common/utils/truncateString";
import { Avatar, Box, Grid, SxProps } from "@mui/material";
import { MouseEvent } from "react";
import { User } from "../../users/types/User";

type UserCardProps = {
  added: boolean;
  hideButton?: boolean;
  onAddUserClick: () => void;
  onRemoveUserClick: () => void;
  setIsSearching?: (isSearching: boolean) => void;
  sx?: SxProps;
  user: User;
};

export default function UserCard(props: UserCardProps) {
  const { added, hideButton, onAddUserClick, onRemoveUserClick, setIsSearching, sx, user } = props;

  const handleAddClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onAddUserClick();
    if (setIsSearching) setIsSearching(false);
  };

  const handleRemoveClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onRemoveUserClick();
  };

  const userRole = user?.profile === "super" ? "Superadmin" : capitalizeString(user?.profile as string);

  return (
    <Grid className="lqd-user-card" container sx={{ backgroundColor: "transparent", pl: 3, pr: 5, py: 2, ...sx }}>
      <Grid item sx={{ alignItems: "center", display: "flex", justifyContent: "flex-start" }} xs={9}>
        <Avatar
          sx={{ backgroundColor: "rgba(191, 196, 205, 0.16)", color: "rgba(56, 61, 70, 1)", fontSize: "14px", mr: 2 }}
        >
          {user?.name ? stringInitials(user?.name).toUpperCase() : null}
        </Avatar>
        <Box sx={{ width: "280px" }}>
          <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="p2Paragraph">
            {user?.name}
          </LqdTypography>
          <LqdTypography sx={{ color: "rgba(127, 135, 152, 1)" }} textstyle="p2Paragraph">
            {truncateString(user?.email, 30)}
          </LqdTypography>
        </Box>
        <LqdChip
          chipbackgroundcolor="rgba(155, 162, 175, 1)"
          chipcolor="rgba(255, 255, 255, 1)"
          chipsize="small"
          label={userRole}
        />
      </Grid>
      <Grid item sx={{ alignItems: "center", display: "flex", justifyContent: "flex-end" }} xs={3}>
        {!hideButton ? (
          <LqdLink
            onMouseDown={added ? handleRemoveClick : handleAddClick}
            sx={{ color: added ? "rgba(246, 61, 94, 1)" : "rgba(33, 36, 42, 1)" }}
          >
            {added ? "Remover" : "Adicionar"}
          </LqdLink>
        ) : null}
      </Grid>
    </Grid>
  );
}
