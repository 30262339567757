import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PDFGeneralInfoCard from "../PDFGeneralinfoCard";
import { ProviderData } from "@analysis/types/AnalysisDetails/Provider/ProviderData";
import { dateFormatter } from "@common/utils/dataFormatter";

interface Props {
  nucleaCertificate: ProviderData["nuclea_income_certificate"];
}

const styles = StyleSheet.create({
  generalInfoCardContainer: {
    display: "flex",
    flexDirection: "row",
  },
  generalInfoText: {
    color: "rgb(22, 53, 57)",
    fontSize: "24px",
    paddingBottom: "40px",
    paddingTop: "40px",
  },
});

export default function PDFLiquidpassNuclea(props: Props) {
  const { nucleaCertificate } = props;

  return (
    <View minPresenceAhead={40}>
      <Text style={styles.generalInfoText}>Informações Gerais</Text>
      <View style={styles.generalInfoCardContainer}>
        <PDFGeneralInfoCard title="Tipo de Moradia" value={nucleaCertificate?.tipoMoradiaFaixa || "Indisponível"} />
        <PDFGeneralInfoCard
          title="ID Núclea"
          value={nucleaCertificate?.idNuclea ? nucleaCertificate.idNuclea : "Indisponível"}
        />
        <PDFGeneralInfoCard
          title="Data de Emissão"
          value={
            nucleaCertificate?.dataEmissao
              ? dateFormatter(nucleaCertificate.dataEmissao * 1000).fullDate
              : "Indisponível"
          }
        />
        <PDFGeneralInfoCard
          title="Data de Validade"
          value={
            nucleaCertificate?.dataEmissao
              ? dateFormatter(nucleaCertificate.dataValidade * 1000).fullDate
              : "Indisponível"
          }
        />
      </View>
    </View>
  );
}
