import {
  LqdDownArrowIcon,
  LqdInput,
  LqdSelect,
  LqdTrashIcon,
  LqdTypography,
  LqdUpArrowIcon,
} from "@/liquid-components/src";
import { LqdInputMaskType } from "@/liquid-components/src/components/Input/types";
import { Box, Card, CardContent, IconButton, Slider, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Node } from "react-flow-renderer/dist/nocss/esm";
import { ObjectOf } from "../../../common/types/ObjectOf";
import Kpi from "../../types/Kpi";
import { ProviderNodeData } from "../../types/NodeData";
import ProviderCondition from "../../types/ProviderCondition";
import { ProviderOperator } from "../../types/ProviderOperator";

type ProviderConditionCardProps = {
  activeNode: Node<ProviderNodeData>;
  condition: ProviderCondition;
  kpis: Array<Kpi>;
  multiple: boolean;
  onDeleteCondition: () => void;
  onMoveConditionDown: () => void;
  onMoveConditionUp: () => void;
  onUpdateCondition: (condition: ProviderCondition) => void;
  selectedKpi: Kpi;
  selectedKpiFriendlyName: string;
};

const getConditionValueRange = (ratingValuesList: Array<string>, value: string) => {
  const splittedvalue = value.split(",");
  const startValue = splittedvalue[0];
  const endValue = splittedvalue[splittedvalue.length - 1];
  const startValueIndex = ratingValuesList.findIndex((value) => value === startValue);
  const endValueIndex = ratingValuesList.findIndex((value) => value === endValue);
  return [startValueIndex < 0 ? 0 : startValueIndex, endValueIndex < 0 ? 1 : endValueIndex];
};

export default function ProviderConditionCard(props: ProviderConditionCardProps) {
  const {
    condition,
    multiple,
    onDeleteCondition,
    onMoveConditionDown,
    onMoveConditionUp,
    onUpdateCondition,
    selectedKpi,
    selectedKpiFriendlyName,
  } = props;

  const theme = useTheme();

  const kpiIsHabitationType = selectedKpi.kpi === "tipoMoradiaFaixa";
  const propsConditionValue = condition.value as string;
  const kpiIsRating = selectedKpi.value_type === "rating";
  const ratingValuesList = Array.isArray(selectedKpi.value_options)
    ? selectedKpi.value_options
    : selectedKpi.value_options?.split(",") || [];

  const [conditionOperator, setConditionOperator] = useState<ProviderOperator | null>(condition.operator);
  const [conditionValueRange, setConditionValueRange] = useState(
    kpiIsRating ? getConditionValueRange(ratingValuesList, propsConditionValue) : []
  );
  const [conditionValue, setConditionValue] = useState<string>(
    kpiIsRating ? (selectedKpi.value_options as string) : propsConditionValue
  );
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  useEffect(() => {
    const conditionOperatorChanged = conditionOperator !== condition.operator;
    const conditionValueChanged = conditionValue !== propsConditionValue;
    const initialConditionValueRange = kpiIsRating ? getConditionValueRange(ratingValuesList, propsConditionValue) : [];
    const conditionValueRangeChanged =
      initialConditionValueRange[0] !== conditionValueRange[0] ||
      initialConditionValueRange[1] !== conditionValueRange[1];
    const anythingChanged = conditionOperatorChanged || conditionValueChanged || conditionValueRangeChanged;
    if (anythingChanged) {
      const updatedCondition = {
        id: condition.id,
        operator: kpiIsRating ? ProviderOperator["Está em"] : conditionOperator,
        value: kpiIsRating
          ? ratingValuesList.slice(conditionValueRange[0], conditionValueRange[1] + 1).join(",")
          : conditionValue,
      };
      onUpdateCondition(updatedCondition);
    }
  }, [conditionOperator, conditionValue, conditionValueRange[0], conditionValueRange[1]]);

  const getConditionValueRangeLabel = () =>
    conditionValueRange[0] === conditionValueRange[1]
      ? `equal to ${ratingValuesList[conditionValueRange[0]]}`
      : `between ${ratingValuesList[conditionValueRange[0]].toUpperCase()} and ${ratingValuesList[
          conditionValueRange[1]
        ].toUpperCase()}`;

  const validateConditionValue = (value: string) => {
    if (Number(value) > 1 && selectedKpi.value_type === "decimal") {
      return setConditionValue("100%");
    }

    if (selectedKpiFriendlyName === "Score de Crédito") {
      if (Number(value) <= 1000) {
        setShowErrorMessage(false);
        return setConditionValue(value);
      } else {
        setShowErrorMessage(true);
        return setConditionValue("1000");
      }
    }

    return setConditionValue(value);
  };

  const kpiInputMaskLookup: ObjectOf<LqdInputMaskType> = {
    currency: "currency",
    decimal: "percentage",
    integer: "integer",
    rating: "string",
  };

  const kpiInputPlaceholderLookup = {
    boolean: "Ex: Sim",
    currency: "Ex: R$ 1.000,00",
    decimal: "Ex: 15%",
    default: "Insira o valor aqui",
    integer: "Ex: 800",
    rating: "Ex: A",
  };

  const kpiOperatorOptions =
    selectedKpi.operators.map((oper) => ({ id: oper, label: ProviderOperator[oper], value: oper })) || [];

  return (
    <Card elevation={0} sx={{ ":last-child": { mb: 0 }, borderRadius: "10px", mb: 1, mt: 1 }}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="l2Label">
            {`Se ${selectedKpiFriendlyName} for: `}
          </LqdTypography>
          <LqdTypography color="rgba(127, 135, 152, 1)" textstyle="c1Caption">
            {kpiIsRating ? getConditionValueRangeLabel() : ""}
          </LqdTypography>
        </Box>

        {kpiIsRating ? (
          <Box sx={{ boxSizing: "border-box", px: 1, width: "100%" }}>
            <Slider
              marks={ratingValuesList.map((rating, index) => ({
                label: rating.toUpperCase(),
                value: index,
              }))}
              max={ratingValuesList.length - 1}
              min={0}
              onChange={(_event, newValue) => {
                setConditionValueRange(newValue as Array<number>);
              }}
              step={1}
              sx={{ mt: 1 }}
              value={conditionValueRange}
            />
          </Box>
        ) : (
          <>
            <Box mb={1}>
              <LqdSelect
                onChange={(value) => setConditionOperator(value as ProviderOperator | null)}
                options={kpiOperatorOptions}
                placeholder="Selecione aqui"
                value={conditionOperator!}
              />
            </Box>
            <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mb: 1 }} textstyle="l2Label">
              O valor:
            </LqdTypography>

            {kpiIsHabitationType ? (
              <LqdSelect
                onChange={(value) => setConditionValue(value as string)}
                options={(selectedKpi.value_options as Array<string>).map((option, index) => ({
                  id: index.toString(),
                  label: option,
                  value: option,
                }))}
                placeholder="Selecione aqui"
                value={conditionValue}
              />
            ) : (
              <LqdInput
                boxSize="small"
                maskType={kpiInputMaskLookup[selectedKpi.value_type]}
                placeholderText={
                  kpiInputPlaceholderLookup[selectedKpi.value_type as keyof typeof kpiInputPlaceholderLookup] ||
                  kpiInputPlaceholderLookup.default
                }
                setValue={validateConditionValue}
                value={conditionValue}
              />
            )}

            {showErrorMessage && selectedKpiFriendlyName === "Score de Crédito" ? (
              <LqdTypography color="rgba(246, 61, 94, 1)" textstyle="p2Paragraph">
                O Score de Crédito vai de 0 a 1000. Digite um número dentro deste intervalo.
              </LqdTypography>
            ) : null}
          </>
        )}

        <Box
          sx={{
            borderTop: "1px solid rgba(240, 241, 243, 1)",
            display: "flex",
            justifyContent: multiple ? "space-between" : "flex-end",
            mt: 1,
            pt: 2,
          }}
        >
          {multiple ? (
            <Box>
              <IconButton onClick={onMoveConditionUp}>
                <LqdUpArrowIcon color={theme.palette.text.disabled} size={24} />
              </IconButton>
              <IconButton onClick={onMoveConditionDown}>
                <LqdDownArrowIcon color={theme.palette.text.disabled} size={24} />
              </IconButton>
            </Box>
          ) : null}
          <IconButton onClick={onDeleteCondition}>
            <LqdTrashIcon color={theme.palette.text.disabled} />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}
