type DashboardFrameProps = {
  embedUrl: string;
  onLoad: () => void;
};

export default function DashboardFrame(props: DashboardFrameProps) {
  const { embedUrl, onLoad } = props;

  return (
    <iframe
      onLoad={onLoad}
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation allow-modals"
      src={embedUrl}
      style={{ border: "none", minHeight: "100%", width: "100%" }}
    />
  );
}
