export enum ProviderOperator {
  "!=" = "Diferente de",
  "<" = "Menor que",
  "<=" = "Menor ou igual a",
  "==" = "Igual a",
  ">" = "Maior que",
  ">=" = "Maior ou igual a",
  "Diferente de" = "!=",
  "Está em" = "in",
  "Igual a" = "==",
  in = "Está em",
  "Maior ou igual a" = ">=",
  "Maior que" = ">",
  "Menor ou igual a" = "<=",
  "Menor que" = "<",
}
