import { onLoadAppVersion } from "@common/commonSlice";
import { env } from "@common/utils/apiUtils";
import SimulatorForm from "@simulatorBuilder/components/SimulatorForm";
import { Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route } from "react-router-dom";
import AccountPage from "../features/account/routes/account";
import { onGetAutomationModules, onGetAutomations } from "../features/automations/automationsSlice";
import AutomationDetailsView from "../features/automations/components/AutomationDetailsView";
import AutomationsList from "../features/automations/components/AutomationsList";
import AutomationsPage from "../features/automations/routes/automations";
import MainLoadingScreen from "../features/common/components/MainLoadingScreen";
import SettingsPageLayout from "../features/common/components/SettingsPageLayout";
import SystemLayout from "../features/common/components/SystemLayout";
import useAuth from "../features/common/hooks/useAuth";
import { useSocket } from "../features/common/hooks/useSocket";
import { useErrorHandler } from "../features/common/utils/useErrorHandler";
import { onLoadGroups, onLoadLoggedUserGroups } from "../features/groups/groupsSlice";
import GroupsPage from "../features/groups/routes/groups";
import { onLoadDashboards } from "../features/play/playSlice";
import PlayDetails from "../features/play/routes/play-details";
import { onLoadGroupProducts, onLoadTenantProducts } from "../features/products/productsSlice";
import {
  onLoadSimulatorFunctionTemplates,
  onLoadSimulatorVariableTypeTemplates,
  onLoadSimulators,
} from "../features/simulatorBuilder/simulatorBuilderSlice";
import {
  onLoadGroupsAdmin,
  onLoadProducts,
  onLoadProviders,
  onLoadSegments,
  onLoadTemplates,
  onLoadTenants,
} from "../features/superadmin/adminSliceThunks";
import AdminPage from "../features/superadmin/routes/admin";
import UsersPage from "../features/users/routes/users";
import { onLoadCurrentTenant, onLoadCurrentUser, onLoadUsers } from "../features/users/usersSlice";
import { useAppDispatch, useAppSelector } from "../store";

const HomePage = lazy(() => import("../features/home/routes/home"));
const PlayPage = lazy(() => import("../features/play/routes/play"));
const BoardPage = lazy(() => import("../features/board/routes/board"));
const ProductBuilderPage = lazy(() => import("../features/productBuilder/routes/product-builder"));
const ProductsPage = lazy(() => import("../features/products/routes/products"));

export default function ProtectedRoutes() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { accessToken } = useAuth();
  const { dispatchWsMessage, lastJsonMessage, sendJsonMessage } = useSocket(accessToken || "null", "protectedRouter");

  const currentUser = useAppSelector((state) => state.users.currentUser);

  const [loadingAppData, setLoadingAppData] = useState(true);
  const [websocketInterval, setWebsocketInterval] = useState<NodeJS.Timeout | null>(null);

  const role = currentUser?.userRole;
  const actions: Array<{ action: () => Promise<unknown>; errorMessage: string }> = [];
  const errorMessage = (value: string) => `Houve um erro ao carregar os ${value}. Tente novamente.`;

  // Loadings iniciais da aplicação no Redux.
  useEffect(() => {
    const initializeCurrentUser = async () => {
      try {
        await dispatch(onLoadCurrentUser());
      } catch (error) {
        const { status } = handleLiquidErrors(error, t("Houve um erro ao carregar o seu usuário. Tente novamente."));

        if (status === 403) {
          setTimeout(initializeCurrentUser, 2000); // Se der 403 na primeira request, aguarda 2s e tenta novamente.
        }
      }
    };
    initializeCurrentUser();
  }, []);

  // Carrega a lista de usuários e produtos após o currentUser ter sido carregado.
  useEffect(() => {
    const initializePendingState = async () => {
      // As requests são divididas por Permissões de Usuário, e depois pela ordem que devem ser carregadas.
      // Em seguida, são executadas em paralelo via Promise.all(), e o app aguarda todas terminarem antes de setar o estado de loading para false.
      if (role) {
        // Carrega a Versão da Aplicação, Automações, CurrentTenant, Dashboards, GroupProducts, LoggedUsersGroups e Users (Qualquer Permissão).
        actions.push(
          { action: () => dispatch(onLoadAppVersion()), errorMessage: errorMessage("versão da aplicação") },
          { action: () => dispatch(onGetAutomations()), errorMessage: errorMessage("automações") },
          { action: () => dispatch(onGetAutomationModules()), errorMessage: errorMessage("módulos de automação") },
          { action: () => dispatch(onLoadCurrentTenant()), errorMessage: errorMessage("dados do seu tenant") },
          { action: () => dispatch(onLoadGroupProducts()), errorMessage: errorMessage("seus produtos") },
          { action: () => dispatch(onLoadLoggedUserGroups()), errorMessage: errorMessage("seus grupos") },
          { action: () => dispatch(onLoadUsers()), errorMessage: errorMessage("usuários da aplicação") }
        );

        if (env !== "production") {
          actions.push({ action: () => dispatch(onLoadDashboards()), errorMessage: errorMessage("dashboards") });
        }

        // Carrega os Grupos, Provedores, Segmentos, Simuladores, Templates e TenantProducts (Super / Admin).
        if (role === "super" || role === "admin") {
          actions.push(
            { action: () => dispatch(onLoadGroups()), errorMessage: errorMessage("grupos") },
            { action: () => dispatch(onLoadProviders()), errorMessage: errorMessage("providers") },
            { action: () => dispatch(onLoadSegments()), errorMessage: errorMessage("segmentos disponíveis") },
            {
              action: () => dispatch(onLoadSimulatorFunctionTemplates()),
              errorMessage: errorMessage("templates de funções de simuladores"),
            },
            {
              action: () => dispatch(onLoadSimulatorVariableTypeTemplates()),
              errorMessage: errorMessage("tipos de variáveis de simuladores"),
            },
            { action: () => dispatch(onLoadSimulators()), errorMessage: errorMessage("simuladores") },
            { action: () => dispatch(onLoadTemplates()), errorMessage: errorMessage("templates de produtos") },
            { action: () => dispatch(onLoadTenantProducts()), errorMessage: errorMessage("produtos do tenant") }
          );
        }

        // Carrega os Grupos do Admin, Produtos e Tenants (Super).
        if (role === "super") {
          actions.push(
            { action: () => dispatch(onLoadGroupsAdmin()), errorMessage: errorMessage("grupos") },
            { action: () => dispatch(onLoadProducts()), errorMessage: errorMessage("produtos") },
            { action: () => dispatch(onLoadTenants()), errorMessage: errorMessage("tenants") }
          );
        }

        const promises = actions.map(async (action) => {
          try {
            await action.action();
          } catch (error) {
            handleLiquidErrors(error, action.errorMessage);
          }
        });

        await Promise.all(promises);
      }
      setLoadingAppData(false);
    };

    if (currentUser) {
      initializePendingState();
    }
  }, [currentUser]);

  // Seta a função de reconectar o websocket a cada 5 minutos, ou toda vez que o accessToken mudar.
  useEffect(() => {
    const FIVE_MINUTES = 1000 * 60 * 5;

    if (websocketInterval) {
      clearInterval(websocketInterval);
    }

    // A cada 5 minutos, envia uma mensagem por websocket para manter a conexão ativa.
    const interval = setInterval(() => {
      sendJsonMessage({ keep: "connected" });
    }, FIVE_MINUTES);
    setWebsocketInterval(interval);

    // Retorna a função de cleanup, para fazer o clear dos intervalos.
    return () => {
      if (websocketInterval) {
        clearInterval(websocketInterval);
      }
    };
  }, [accessToken]);

  // Recebe e processa uma mensagem recebida via websocket.
  useEffect(() => {
    if (lastJsonMessage) {
      const { action, payload, status_code } = lastJsonMessage;
      if (action && payload && status_code) {
        dispatchWsMessage(lastJsonMessage);
      }
    }
  }, [lastJsonMessage]);

  if (loadingAppData) {
    return <Route element={<MainLoadingScreen />} path="*" />;
  }

  const suspenseElement = (element: JSX.Element) => (
    <Suspense fallback={<MainLoadingScreen delay={150} />}>{element}</Suspense>
  );

  return (
    <>
      <Route element={<SystemLayout />} path="/">
        <Route element={<Navigate replace to={env !== "production" ? "home" : "board"} />} path="" />
        {env !== "production" ? (
          <>
            <Route element={suspenseElement(<HomePage />)} path="home" />
            <Route element={suspenseElement(<PlayPage />)} path="play" />
            <Route element={<PlayDetails />} path="play/:reportId" />
          </>
        ) : null}
        <Route element={suspenseElement(<BoardPage />)} path="board/:analysisId?/:view?" />
        <Route element={suspenseElement(<ProductsPage />)} path="products" />
        <Route element={<AutomationsPage />} path="automations">
          <Route element={<AutomationsList />} path="" />
          <Route element={<AutomationDetailsView />} path=":automationId" />
        </Route>
        {currentUser?.userRole === "super" || currentUser?.userRole === "admin" ? (
          <Route element={<SettingsPageLayout />} path="config">
            <Route element={<AccountPage />} path="account" />
            <Route element={<GroupsPage />} path="groups" />
            <Route element={<UsersPage />} path="users" />
            {currentUser?.userRole === "super" ? (
              <>
                <Route element={<AdminPage />} path="admin" />
                <Route element={<SimulatorForm />} path="admin/:simulatorId" />
                <Route element={<Navigate replace to="admin" />} path="" />
              </>
            ) : (
              <Route element={<Navigate replace to="account" />} path="" />
            )}
          </Route>
        ) : null}
      </Route>
      <Route element={suspenseElement(<ProductBuilderPage />)} path="products/:productId" />
    </>
  );
}
