/**
 * Envia informações de contato de um colega para um certificado de renda.
 *
 * @param {CertificatePeerContact} data - Os dados de contato do peer a serem enviados.
 * @returns {Promise<GlobalAction>} Uma promessa que resolve para uma GlobalAction.
 */
import { post } from "../../common/utils/apiUtils";
import { GlobalAction } from "../types/ActionType";
import { CertificatePeerContact } from "../types/IncomeCertificatePeerContact";

export const sendPeerContact = (data: CertificatePeerContact) =>
  post<GlobalAction>("/income-certificate/peer-contact", {
    contact_values: data.contactValues,
    pendency_id: data.pendencyId,
    provider: data.provider,
    tenant_code: data.tenantCode,
  });
