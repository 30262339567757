import { updatePendencies } from "@analysis/api/UpdatePendencies";
import { AnalysisPendency } from "@analysis/types/AnalysisDetails/Analysis/AnalysisPendency";
import { PeerData } from "@analysis/types/AnalysisDetails/PeerData/PeerData";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getActions } from "./api/GetActions";
import { sendPeerContact } from "./api/SendPeerContact";
import { GlobalAction } from "./types/ActionType";
import { CertificatePeerContact } from "./types/IncomeCertificatePeerContact";
import { IncomeCertificatePendencyView } from "./types/IncomeCertificatePendencyView";
import { UpdatePendencyType } from "./types/UpdatePendencyType";

export interface IncomeCertificateState {
  action: GlobalAction | null;
  agreementChannel: string;
  currentPendency: AnalysisPendency | null;
  currentView: IncomeCertificatePendencyView | null;
  destination: string;
  email: string;
  idNuclea: string;
  loading: boolean;
  modalView: IncomeCertificatePendencyView | null;
  openModal: boolean;
  peer: PeerData | null;
  pendencyKpi: string;
  phone: string;
}

const initialState: IncomeCertificateState = {
  action: null,
  agreementChannel: "",
  currentPendency: null,
  currentView: null,
  destination: "",
  email: "",
  idNuclea: "",
  loading: false,
  modalView: null,
  openModal: false,
  peer: null,
  pendencyKpi: "",
  phone: "",
};

export const onPeerContact = createAsyncThunk(
  "certificate/onSendingContact",
  async (data: CertificatePeerContact, thunk) => {
    try {
      const response = await sendPeerContact(data);
      return response.data;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);

export const onUpdatePendencyValue = createAsyncThunk(
  "certificate/onUpdatePendency",
  async (data: UpdatePendencyType, thunk) => {
    try {
      const response = await updatePendencies(data.analysisId, data.pendencies);
      return response.data[0];
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);

export const onLoadActions = createAsyncThunk("certificate/onLoadActions", async (analysisId: string, thunk) => {
  try {
    const response = await getActions(analysisId);
    return response.data;
  } catch (error) {
    return thunk.rejectWithValue(error);
  }
});

const certificateSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(onPeerContact.fulfilled, (state, action) => {
        state.action = action.payload as GlobalAction;
        state.loading = false;
      })
      .addCase(onLoadActions.fulfilled, (state, action) => {
        state.action = action.payload as unknown as GlobalAction;
      });
  },
  initialState,
  name: "incomeCertificate",
  reducers: {
    setAction(state, action: PayloadAction<GlobalAction | null>) {
      state.action = action.payload;
    },
    setCurrentPendency(state, action: PayloadAction<AnalysisPendency>) {
      state.currentPendency = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setIdNuclea(state, action: PayloadAction<string>) {
      state.idNuclea = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setModalView(state, action: PayloadAction<IncomeCertificatePendencyView | null>) {
      state.modalView = action.payload;
    },
    setOpenModal(state, action: PayloadAction<boolean>) {
      state.openModal = action.payload;
    },
    setPeer(state, action: PayloadAction<PeerData>) {
      state.peer = action.payload;
    },
    setPhone(state, action: PayloadAction<string>) {
      state.phone = action.payload;
    },
  },
});

export const {
  setAction,
  setCurrentPendency,
  setEmail,
  setIdNuclea,
  setLoading,
  setModalView,
  setOpenModal,
  setPeer,
  setPhone,
} = certificateSlice.actions;

export default certificateSlice.reducer;
