import { PeerContactResponseType } from "@analysis/types/AnalysisDetails/Nuclea/PeerContactResponseType";
import { put } from "../../common/utils/apiUtils";

type RequestBodyType = {
  passId: string;
  phone: string;
};

/**
 * Endpoint que atualiza as informações no banco Identities de um peer pelo Pass ID.
 * @param analysisId AnalysisId do usuário.
 * @param requestBody Dados do peer (pass_id e telefone).
 * @returns Dados do peer.
 * @remarks Endpoint SEM VALIDAÇÃO DE USER ROLE.
 */
export const updateUserPhone = (analysisId: string, requestBody: RequestBodyType) => {
  return put<PeerContactResponseType>(`/analysis/${analysisId}/update/peer-phone`, {
    new_phone: requestBody.phone,
    pass_id: requestBody.passId,
  });
};
