import { Box, SxProps } from "@mui/material";

type LiquidAILogoProps = {
  color?: string;
  height?: number;
  sx?: SxProps;
  width?: number;
};

export default function LiquidAILogo(props: LiquidAILogoProps) {
  const { color = "black", height, sx, width } = props;

  const heightValue = height ? `${height}` : "196";
  const widthValue = width ? `${width}` : "598";

  return (
    <Box sx={{ ...sx }}>
      <svg fill="none" height={heightValue} viewBox="0 0 115 38" width={widthValue} xmlns="http://www.w3.org/2000/svg">
        <path d="M114.442 5.01186H113.706V0.226074H114.442V5.01186Z" fill={color} />
        <path
          d="M20.6241 4.5018C20.5037 4.5018 20.3835 4.49321 20.2632 4.47315C19.8192 4.40152 19.4182 4.18375 19.1117 3.87715C18.8053 3.57056 18.5876 3.16942 18.516 2.72528C18.2754 1.26395 19.5242 0.0175287 20.9849 0.255341C21.8728 0.401476 22.586 1.11495 22.7321 2.00322C22.9527 3.3442 21.9216 4.5018 20.6241 4.5018Z"
          fill={color}
        />
        <path
          d="M5.03406 24.8229C5.70718 25.522 6.63518 25.8831 7.85248 25.8831V25.8859H15.732V29.0436H7.77227C6.30579 29.0436 5.01402 28.7312 3.91417 28.0951C2.81715 27.4619 1.96935 26.5794 1.3793 25.4533C0.792157 24.3301 0.5 23.0578 0.5 21.6424V0.226694H3.98004V22.0464C3.98004 23.1667 4.3266 24.0779 5.01115 24.8L5.03406 24.8229Z"
          fill={color}
        />
        <path d="M22.3254 7.86287H18.9255V29.0407H22.3254V7.86287Z" fill={color} />
        <path
          clipRule="evenodd"
          d="M101.117 10.4789V0.226694H104.52V18.4905C104.52 19.6567 104.4 20.7398 104.159 21.7398C103.919 22.7398 103.555 23.6596 103.068 24.4934C102.1 26.1553 100.802 27.4189 99.1783 28.2785C97.5571 29.1381 95.7699 29.5651 93.8136 29.5651C93.0832 29.5651 92.37 29.502 91.6769 29.3731C90.9837 29.2441 90.3078 29.055 89.6519 28.7972C88.3372 28.2871 87.1858 27.545 86.2005 26.5736C85.2152 25.5994 84.4447 24.416 83.8948 23.0206C83.3391 21.6223 83.067 20.0435 83.067 18.2899C83.067 17.1638 83.1845 16.118 83.4193 15.1466C83.6542 14.1752 84.0065 13.2841 84.4791 12.4675C85.4157 10.8514 86.6731 9.59641 88.2398 8.70814C89.8065 7.81988 91.5451 7.37863 93.4498 7.37863C96.168 7.37863 99.0494 8.23536 101.117 10.4789ZM97.4483 25.5937C98.5424 24.9776 99.4217 24.075 100.083 22.8773C100.742 21.6939 101.077 20.2326 101.077 18.4933C101.077 16.7541 100.734 15.2956 100.063 14.1093C99.7253 13.5105 99.3358 12.9833 98.8919 12.5277C98.4479 12.0692 97.9524 11.6852 97.4053 11.3672C96.3055 10.7339 95.0681 10.4159 93.7706 10.4159C92.4731 10.4159 91.2788 10.7397 90.1789 11.3873C89.0819 12.032 88.1969 12.9546 87.518 14.1667C86.8449 15.3701 86.5041 16.7397 86.5041 18.4503C86.5041 19.3071 86.59 20.0979 86.759 20.8257C86.928 21.5535 87.1829 22.2154 87.518 22.8171C87.856 23.4217 88.2513 23.9547 88.6981 24.4132C89.1506 24.8716 89.6547 25.2584 90.2161 25.5736C91.3446 26.2097 92.5562 26.5278 93.8537 26.5278C94.501 26.5278 95.1254 26.4504 95.724 26.2928C96.3227 26.1381 96.8955 25.9031 97.4483 25.5937Z"
          fill={color}
          fillRule="evenodd"
        />
        <path
          d="M71.9109 20.1495V7.86287H68.511V20.0292C68.511 21.4131 68.2103 22.5793 67.6231 23.5335C67.0302 24.502 66.2511 25.2412 65.2888 25.7541C64.3206 26.2699 63.281 26.5278 62.1725 26.5278C61.064 26.5278 60.0701 26.2785 59.1164 25.7742C58.1683 25.2756 57.3892 24.5364 56.782 23.5535C56.4813 23.0693 56.2579 22.5335 56.1061 21.9461C55.9543 21.3587 55.8769 20.7226 55.8769 20.0292V7.86287H52.4771V20.1495C52.4771 22.0521 52.9125 23.7226 53.7918 25.1467C54.6654 26.565 55.8426 27.6596 57.3148 28.4246C58.0509 28.8057 58.8242 29.0923 59.6348 29.2814C60.4454 29.4734 61.2903 29.5679 62.1725 29.5679C63.9368 29.5679 65.6382 29.1868 67.1104 28.4246C67.8465 28.0407 68.5081 27.5765 69.0925 27.0321C69.6768 26.4848 70.1837 25.8573 70.6162 25.1467C71.4812 23.7197 71.9109 22.0521 71.9109 20.1495Z"
          fill={color}
        />
        <path
          d="M75.9752 3.87715C76.2845 4.18375 76.6855 4.40152 77.1295 4.47315C78.5874 4.71098 79.8362 3.46454 79.5956 2.00322C79.4495 1.11495 78.7363 0.401476 77.8484 0.255341C76.3876 0.0175287 75.1417 1.26395 75.3823 2.72528C75.4539 3.16942 75.6687 3.57056 75.9752 3.87715Z"
          fill={color}
        />
        <path
          clipRule="evenodd"
          d="M37.5172 7.06629H37.523C43.8185 7.13219 48.9197 12.2726 48.9197 18.5879C48.9197 23.5851 45.7204 27.8487 41.2637 29.439C41.1388 29.4889 41.0151 29.5389 40.8939 29.5888C40.8204 29.6191 40.7479 29.6494 40.6765 29.6797C39.7313 30.0866 39.2043 30.7427 38.9379 31.5278C38.7804 31.9891 38.7145 32.5021 38.7145 33.0379C38.7145 33.4247 38.7489 33.823 38.809 34.2241L38.846 34.4642C38.8578 34.5405 38.869 34.6129 38.8797 34.6814L38.8934 34.7693L38.8983 34.7998C38.901 34.8166 38.9036 34.8332 38.9063 34.8495C38.9111 34.8796 38.9159 34.9088 38.9207 34.9371C38.9265 34.972 38.9322 35.0056 38.9379 35.0379C38.9723 35.2471 39.0009 35.3961 39.021 35.4849C39.0697 35.6912 39.1069 35.9004 39.1069 36.1153C39.1069 36.3474 39.0639 36.5823 38.9494 36.823C38.6343 37.4734 38.0414 37.7743 37.4485 37.7743C36.6551 37.7743 35.8674 37.2385 35.7586 36.2757C35.7529 36.2213 35.75 36.164 35.75 36.1038C35.75 35.9491 35.7701 35.7714 35.8044 35.5794C35.8074 35.5613 35.8104 35.5432 35.8134 35.5252C35.8168 35.5051 35.8203 35.4851 35.8237 35.465C35.8272 35.445 35.8306 35.4251 35.8341 35.4052L35.8402 35.3703C35.8524 35.3008 35.8646 35.2313 35.876 35.1611C36.0107 34.4276 36.1367 33.7284 36.1367 33.0236C36.1367 32.7943 36.1224 32.5622 36.0909 32.3301C36.0078 31.7026 35.8073 31.1582 35.4808 30.7055C35.1543 30.2556 34.7017 29.9003 34.1117 29.651L34.0451 29.6231C33.9926 29.6012 33.9345 29.5774 33.8711 29.5536C29.2396 28.0579 25.8828 23.7083 25.8828 18.585C25.8828 12.2297 31.0498 7.06343 37.3998 7.06343L37.5172 7.06629ZM28.979 18.585C28.979 23.2298 32.7569 27.0092 37.3998 27.0092C42.0427 27.0092 45.8206 23.2298 45.8206 18.585C45.8206 13.9403 42.0427 10.1609 37.3998 10.1609C32.7569 10.1609 28.979 13.9403 28.979 18.585Z"
          fill={color}
          fillRule="evenodd"
        />
        <path d="M75.789 7.86287H79.186V29.0407H75.789V7.86287Z" fill={color} />
        <path
          clipRule="evenodd"
          d="M108.839 5.01186H109.615L110.01 3.8606H112.012L112.407 5.01186H113.21L111.416 0.226074H110.619L108.839 5.01186ZM110.231 3.23811L111.008 0.908802H111.021L111.804 3.23811H110.231Z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </Box>
  );
}
