import { highlightText } from "@/analysis-v3/src/features/common/utils/highlightText";
import { SimulatorTesterSearchData } from "@/analysis-v3/src/features/simulatorBuilder/types/simulationTester";
import { Circle } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";
import LqdTooltip from "../Tooltip/Tooltip";
import LqdTypography from "../Typography/Typography";

type LqdInfoCardProps = {
  cardSubtitle?: string;
  cardTitle: string;
  cardType?: "highlight" | "insight";
  cardValue: ReactNode | number | string;
  displayLqdIcon?: ReactNode; // The icon showed in the display MUST be 20px size!
  displayToolTip?: ReactNode; // The icon showed in the display MUST be 20px size!
  inputSearchData?: SimulatorTesterSearchData;
  kpiEvaluation?: boolean;
  toolTipText?: string;
  type?: "card" | "container";
};

export default function LqdInfoCard(props: LqdInfoCardProps) {
  const {
    cardSubtitle,
    cardTitle,
    cardType,
    cardValue,
    displayLqdIcon,
    displayToolTip,
    inputSearchData,
    kpiEvaluation,
    toolTipText,
    type,
  } = props;

  const setDotBadgeColor = () => {
    switch (kpiEvaluation) {
      case true:
        return "rgba(52, 199, 89, 1)";
      case false:
        return "rgba(249, 108, 120, 1)";
      default:
        return "rgba(240, 241, 243, 1)";
    }
  };

  const LqdinfoCardDotBadge = <Circle sx={{ color: setDotBadgeColor(), height: "8px", width: "8px" }} />;

  const renderIcon = () => {
    switch (true) {
      case !!displayLqdIcon:
        return displayLqdIcon;
      case !!displayToolTip:
        return displayToolTip;
      default:
        return LqdinfoCardDotBadge;
    }
  };

  const baseComponent = (
    <Stack
      direction="column"
      spacing="auto"
      sx={{
        backgroundColor: "rgba(252, 252, 252, 1)",
        border: "1px solid rgba(212, 215, 220, 1)",
        borderRadius: "12px",
        height: type === "container" ? "auto" : "140px",
        padding: "12px 16px",
        width: type === "container" ? "100%" : "244px",
      }}
    >
      <Box sx={{ alignSelf: "flex-end", display: "inline-flex" }}>
        {/* Always check the TooltipIcon size!! */}
        {renderIcon()}
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: cardType === "highlight" ? "row" : "column",
            gap: cardType === "highlight" ? 0.5 : null,
          }}
        >
          <LqdTypography color="rgba(75, 75, 75, 1)">
            {inputSearchData && inputSearchData.searchedText
              ? highlightText(cardTitle, inputSearchData.searchedText)
              : cardTitle}
          </LqdTypography>
          <LqdTypography
            color="rgba(155, 162, 175, 1)"
            sx={{ overflowWrap: "break-word", pt: 0.3 }}
            textstyle="p2Paragraph"
          >
            {cardSubtitle}
          </LqdTypography>
        </Box>
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="h5Headline">
          {cardValue}
        </LqdTypography>
      </Box>
    </Stack>
  );

  return displayToolTip ? (
    <LqdTooltip followCursor leaveDelay={100} titlebody={toolTipText || ""}>
      {baseComponent}
    </LqdTooltip>
  ) : (
    baseComponent
  );
}
