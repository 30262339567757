import { ObjectOf } from "@common/types/ObjectOf";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDashboards } from "./api/GetDashboards";
import { Dashboard } from "./types/Dashboard";
import { DashboardRaw } from "./types/DashboardRaw";
import { parseDashboard } from "./utils/parseDashboard";

export type PlayFilter = {
  deactivated: boolean;
  "one-shot": boolean;
  play: boolean;
  "play+": boolean;
};

interface PlayState {
  dashboards: ObjectOf<Dashboard>; // Dashboards
  filter: PlayFilter; // Filtro do play
}

const initialState: PlayState = {
  dashboards: {},
  filter: {
    deactivated: true,
    "one-shot": true,
    play: true,
    "play+": true,
  },
};

export const onLoadDashboards = createAsyncThunk("play/onLoadDashboards", async () => {
  const { data } = await getDashboards();

  return data;
});

const playSlice = createSlice({
  extraReducers: (builder) =>
    builder.addCase(onLoadDashboards.fulfilled, (state, action) => {
      const dashboards = action.payload;

      const parsedDashboards = dashboards.map((dashboard: DashboardRaw) => parseDashboard(dashboard));

      parsedDashboards.forEach((dashboard) => {
        state.dashboards[dashboard.reportId] = dashboard;
      });
    }),
  initialState,
  name: "play",
  reducers: {
    playFilterToggled(state, action: PayloadAction<{ name: keyof PlayFilter; value: boolean }>) {
      const { name, value } = action.payload;
      state.filter[name] = value;
    },
  },
});

export const { playFilterToggled } = playSlice.actions;

export default playSlice.reducer;
