import { LqdSelect, LqdToggle, LqdTypography } from "@/liquid-components/src";
import { Card, CardContent, CardMedia, Stack } from "@mui/material";
import { nodeUpdated } from "@productBuilder/productBuilderSlice";
import React, { SetStateAction } from "react";
import { Node } from "react-flow-renderer/dist/nocss/esm";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { ObjectOf } from "../../../common/types/ObjectOf";
import getKey from "../../../common/utils/getKey";
import providerImg from "../../assets/provider.png";
import Kpi from "../../types/Kpi";
import { ProviderNodeData } from "../../types/NodeData";
import { PeerChoice } from "../../types/NodeOptions";
import ProviderCondition from "../../types/ProviderCondition";
import { ProviderOperator } from "../../types/ProviderOperator";
import { SelectKpiOptions, getKpisOptions } from "../../utils/getKpisOptions";
import { getNodeDescription, getProviderNodeKpisData } from "../../utils/nodeContents";
import ProviderConditionCard from "./ProviderConditionCard";
import ProviderHeader from "./ProviderHeader";
import DashedAddButton from "./StartFlow/DashedAddButton";

type ProviderProps = {
  activeNode: Node<ProviderNodeData>;
  activeProponents: ObjectOf<PeerChoice>;
  onClose: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  selectedSimulationId: null | string;
  setActiveProponents: (proponents: ObjectOf<PeerChoice>) => void;
  setSelectedSimulationId: (value: string) => void;
  setTemporaryConditions: (conditions: Array<ProviderCondition>) => void;
  setTemporarySelectedKpi: (kpi: string | null) => void;
  temporaryConditions: Array<ProviderCondition>;
  temporarySelectedKpi: string;
};

export default function Provider(props: ProviderProps) {
  const {
    activeNode,
    activeProponents,
    onClose,
    onDelete,
    onDuplicate,
    selectedSimulationId,
    setActiveProponents,
    setSelectedSimulationId,
    setTemporaryConditions,
    setTemporarySelectedKpi,
    temporaryConditions,
    temporarySelectedKpi,
  } = props;

  const dispatch = useAppDispatch();

  const { nodes, proponents } = useAppSelector((state) => state.productBuilder);
  const { simulatorKpis, simulators } = useAppSelector((state) => state.simulatorBuilder);

  const providerNodeKpiData = getProviderNodeKpisData(activeNode.data);
  const simulatorOptions = Object.values(simulators).map((simulator) => ({
    id: simulator.simulator_id,
    label: simulator.name,
    value: simulator.simulator_id,
  }));
  const selectedSimulatorKpis = selectedSimulationId ? simulatorKpis[selectedSimulationId] : undefined;

  const temporarySelectedKpiFriendlyName =
    providerNodeKpiData[temporarySelectedKpi] ||
    selectedSimulatorKpis?.find((kpi) => kpi?.kpi === temporarySelectedKpi)?.label ||
    "Informação não encontrada";

  const providerIsSimulator =
    activeNode.data.provider === "simulation" && activeNode.data.options.simulation_type === "simulator";
  const shouldRenderSimulationIdSelect = providerIsSimulator && !selectedSimulationId;
  const allKpis = providerIsSimulator && selectedSimulationId ? selectedSimulatorKpis : activeNode.data.options.kpis;
  const selectedKpiExists = allKpis?.find((kpi) => kpi?.kpi === temporarySelectedKpi);

  // Lista de Kpis do nó de provider selecionado
  const kpisOptions =
    providerIsSimulator && selectedSimulationId && selectedSimulatorKpis
      ? // Se o provider for um simulador customizado, pega da lista de KPIs de simuladores, na store
        Object.values(selectedSimulatorKpis).reduce((acc, kpi) => {
          if (kpi) {
            return {
              ...acc,
              [kpi.kpi]: {
                id: kpi.kpi,
                label: providerNodeKpiData[kpi.kpi] || kpi.label || "undefined",
                value: kpi.kpi,
                valueType: kpi.value_type,
              },
            };
          } else {
            return acc;
          }
        }, {} as SelectKpiOptions)
      : // Se o provider for de qualquer outro tipo
        getKpisOptions(activeNode.data);

  const temporarySelectedKpiValueType = kpisOptions[temporarySelectedKpi as string]?.valueType || null;
  const providerIsRegisterUpload = activeNode.data.provider === "register_upload";
  const providerIsRegisterSignature = activeNode.data.provider === "doc_signature";

  const onKpiChange = (selectedKpi: string) => {
    const selectedKpiValueType = kpisOptions[selectedKpi].valueType;
    const newTemporaryConditions =
      selectedKpiValueType === "boolean"
        ? [
            {
              id: getKey(),
              operator: ProviderOperator["Igual a"],
              value: true,
            },
            {
              id: getKey(),
              operator: ProviderOperator["Igual a"],
              value: false,
            },
          ]
        : [];

    setTemporarySelectedKpi(selectedKpi);
    setTemporaryConditions(newTemporaryConditions);
  };

  const onAddNewCondition = () => {
    const newConditions = [...temporaryConditions];
    newConditions.push({
      id: getKey(),
      operator: temporarySelectedKpiValueType === "rating" ? ProviderOperator["Está em"] : null,
      value: "",
    });
    setTemporaryConditions(newConditions);
  };

  const onDeleteCondition = (conditionIndex: number) => {
    const updatedConditions = [...temporaryConditions];
    updatedConditions.splice(conditionIndex, 1);
    setTemporaryConditions(updatedConditions);
  };

  const onUpdateCondition = (updatedCondition: ProviderCondition, index: number) => {
    const updatedConditions = [...temporaryConditions];
    updatedConditions.splice(index, 1, updatedCondition);
    const checkPercentage = (updatedCondition.value as string).includes("%");
    if (checkPercentage) {
      const newUpdatedConditions = updatedConditions.map((condition) => ({
        ...condition,
        value:
          parseFloat((condition.value as string).split("%")[0]) > 1
            ? parseFloat((condition.value as string).split("%")[0]) / 100
            : parseFloat((condition.value as string).split("%")[0]),
      }));
      setTemporaryConditions(newUpdatedConditions);
    } else {
      setTemporaryConditions(updatedConditions);
    }
  };

  const handleMoveConditionUp = (index: number) => {
    if (index > 0) {
      const updatedConditions = [...temporaryConditions];
      const previousCondition = temporaryConditions[index - 1];
      updatedConditions[index - 1] = updatedConditions[index];
      updatedConditions[index] = previousCondition;
      setTemporaryConditions(updatedConditions);
    }
  };

  const handleMoveConditionDown = (index: number) => {
    if (index < temporaryConditions.length - 1) {
      const updatedConditions = [...temporaryConditions];
      const nextCondition = temporaryConditions[index + 1];
      updatedConditions[index + 1] = updatedConditions[index];
      updatedConditions[index] = nextCondition;
      setTemporaryConditions(updatedConditions);
    }
  };

  const renderProviderTitleDescription = () => {
    switch (true) {
      case providerIsRegisterUpload:
        return "Escolha os documentos";
      case providerIsRegisterSignature:
        return "Escolha quem vai assinar o documento";
      default:
        return "Escolha a informação que deseja ver:";
    }
  };

  return (
    <>
      <ProviderHeader
        activeNode={activeNode}
        onClose={onClose}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        selectedSimulationId={selectedSimulationId}
      />
      {shouldRenderSimulationIdSelect ? (
        <LqdSelect
          onChange={setSelectedSimulationId as React.Dispatch<SetStateAction<string>>}
          options={simulatorOptions}
          placeholder="Select the simulation ID"
          value={selectedSimulationId as string}
        />
      ) : (
        <>
          <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ mb: 3 }} textstyle="p2Paragraph">
            {renderProviderTitleDescription()}
          </LqdTypography>
          {providerIsRegisterUpload ? (
            <Stack alignItems="center" direction="row" spacing={1} sx={{ mb: 3 }}>
              <LqdToggle active={false} disabled onChange={() => {}} />
              <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="c1Caption">
                Pré-setar os cartórios
              </LqdTypography>
            </Stack>
          ) : null}
          {providerIsRegisterSignature
            ? proponents.map((proponent) => (
                <Stack alignItems="center" direction="row" key={proponent.id} mb={2.75} spacing={1}>
                  <LqdToggle
                    active={activeProponents[proponent.id]?.selected || false}
                    onChange={(active) => {
                      const latestNode = nodes.find((node) => node.id === activeNode.id);

                      if (!latestNode) return;

                      const updatedNode = {
                        ...latestNode,
                        data: {
                          ...latestNode.data,
                          options: {
                            ...latestNode.data.options,
                            inputs: latestNode.data.options.inputs ? [...latestNode.data.options.inputs] : [],
                          },
                        },
                      };

                      if (updatedNode.data.options.inputs.length > 0) {
                        updatedNode.data.options.inputs[0] = {
                          ...updatedNode.data.options.inputs[0],
                          choices: {
                            ...updatedNode.data.options.inputs[0].choices,
                            [proponent.id]: { id: proponent.id, peer: proponent.name, selected: active },
                          },
                        };
                      }

                      setActiveProponents({
                        ...activeProponents,
                        [proponent.id]: { id: proponent.id, peer: proponent.name, selected: active },
                      });

                      dispatch(nodeUpdated(updatedNode));
                    }}
                  />
                  <LqdTypography color="rgba(155, 162, 175, 1)" textstyle="c1Caption">
                    {proponent.name}
                  </LqdTypography>
                </Stack>
              ))
            : null}
          <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ display: "block", mb: 1 }} textstyle="l2Label">
            Indicador chave:
          </LqdTypography>
          <LqdSelect
            onChange={onKpiChange as unknown as React.Dispatch<React.SetStateAction<string>>}
            options={Object.values(kpisOptions)}
            placeholder="Procure aqui"
            value={temporarySelectedKpi || ""}
          />
          {selectedKpiExists && temporarySelectedKpi && temporarySelectedKpiValueType !== "boolean"
            ? temporaryConditions.map((condition, index) => (
                <ProviderConditionCard
                  activeNode={activeNode}
                  condition={condition}
                  key={condition.id}
                  kpis={
                    providerIsSimulator && selectedSimulationId
                      ? (simulatorKpis[selectedSimulationId] as Array<Kpi>)
                      : activeNode.data.options.kpis
                  }
                  multiple={temporaryConditions.length > 1}
                  onDeleteCondition={() => onDeleteCondition(index)}
                  onMoveConditionDown={() => handleMoveConditionDown(index)}
                  onMoveConditionUp={() => handleMoveConditionUp(index)}
                  onUpdateCondition={(condition: ProviderCondition) => onUpdateCondition(condition, index)}
                  selectedKpi={selectedKpiExists}
                  selectedKpiFriendlyName={temporarySelectedKpiFriendlyName}
                />
              ))
            : null}
          {selectedKpiExists && temporarySelectedKpi && temporarySelectedKpiValueType !== "boolean" ? (
            <DashedAddButton onClick={onAddNewCondition} sx={{ mt: 3 }}>
              nova condição
            </DashedAddButton>
          ) : null}
        </>
      )}
      <Card elevation={0} sx={{ borderRadius: "10px", mt: 1.5 }}>
        <CardMedia component="img" image={providerImg} />
        <CardContent sx={{ px: 4 }}>
          <LqdTypography color="rgba(65, 70, 103, 1)" sx={{ mb: 2 }}>
            Sobre
          </LqdTypography>
          {getNodeDescription(activeNode.data).map((description, index) => (
            <LqdTypography color="rgba(155, 162, 175, 1)" key={`${description}-${index}`} textstyle="c1Caption">
              {description}
            </LqdTypography>
          ))}
        </CardContent>
      </Card>
    </>
  );
}
