import { PayloadAction } from "@reduxjs/toolkit";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
} from "react-flow-renderer";
import getKey from "../common/utils/getKey";
import { ProductBuilderState } from "./productBuilderSlice";
import { ProviderNodeData } from "./types/NodeData";
import Proponent from "./types/Proponent";
import RawNode from "./types/RawNode";
import { validateEdges } from "./utils/validateEdges";

export const productBuilderReducers = {
  activeNodeChanged(state: ProductBuilderState, action: PayloadAction<Node | null>) {
    const activeNode = action.payload;
    state.activeNode = activeNode;
  },

  activeNodesChanged(state: ProductBuilderState, action: PayloadAction<Array<Node> | []>) {
    const activeNodes = action.payload;

    state.activeNodes = activeNodes;
  },

  addSelectedNodes(state: ProductBuilderState, action: PayloadAction<Node>) {
    const nodePayload = action.payload;
    const selectedNodes = state.activeNodes;
    const nodeIndex = selectedNodes.findIndex((node) => node.id === nodePayload.id);

    if (nodeIndex < 0) {
      state.activeNodes.push(nodePayload);
    }
  },

  autoSaveChanged(state: ProductBuilderState, action: PayloadAction<boolean>) {
    const autoSave = action.payload;
    state.autoSave = autoSave;
  },

  clearSelectedNodes(state: ProductBuilderState) {
    state.activeNodes = [];
  },

  connectionAdded(state: ProductBuilderState, action: PayloadAction<Connection>) {
    const connection = action.payload;
    const edges = addEdge(connection, state.edges);
    const validEdges = validateEdges(edges, state.nodes, state.proponents);
    state.edges = validEdges;
  },

  edgeRemoved(state: ProductBuilderState, action: PayloadAction<string>) {
    const edgeId = action.payload;
    const edgeIndex = state.edges.findIndex((edge) => edge.id === edgeId);
    state.edges.splice(edgeIndex, 1);
  },

  edgesChanged(state: ProductBuilderState, action: PayloadAction<Array<EdgeChange>>) {
    const changes = action.payload;
    const changedEdges = applyEdgeChanges(changes, state.edges);
    const validEdges = validateEdges(changedEdges, state.nodes, state.proponents);
    state.edges = validEdges;
  },

  edgesUpdated(state: ProductBuilderState, action: PayloadAction<Array<Edge>>) {
    const edges = action.payload;
    state.edges = edges;
  },

  handleWithSelectedNodes(state: ProductBuilderState, action: PayloadAction<Node>) {
    const nodePayload = action.payload;
    const selectedNodes = state.activeNodes;
    const nodeIndex = selectedNodes.findIndex((node) => node.id === nodePayload.id);

    if (nodeIndex >= 0) {
      state.activeNodes.splice(nodeIndex, 1);
    } else {
      state.activeNodes.push(nodePayload);
    }
  },

  initialRulesChanged(state: ProductBuilderState, action: PayloadAction<Array<RawNode>>) {
    state.initialRules = action.payload;
  },

  nodeDuplicated(state: ProductBuilderState, action: PayloadAction<Node>) {
    const nodeToDuplicate = action.payload;

    const duplicatedNode = {
      data: JSON.parse(JSON.stringify(nodeToDuplicate.data)),
      id: "node" + getKey(),
      position: {
        x: nodeToDuplicate.position.x + 300,
        y: nodeToDuplicate.position.y,
      },
      type: nodeToDuplicate.type,
    };
    // Atribui um novo id às conditions do node, se houver alguma
    const duplicatedNodeAsProvider = duplicatedNode.data as ProviderNodeData;
    const duplicatedNodeAsProviderConditions = duplicatedNodeAsProvider.custom_data?.conditions;
    if (duplicatedNodeAsProviderConditions?.length) {
      duplicatedNodeAsProvider.custom_data.conditions = duplicatedNodeAsProviderConditions.map((condition) => ({
        ...condition,
        id: getKey(),
      }));
    }

    // TODO: manter selecionado somente os nós duplicados
    // Remove a seleção dos nós existentes
    // const unselectActiveNode: Node = { ...nodeToDuplicate, selected: false };

    // const nodeIndex = state.nodes.findIndex((node) => node.id === nodeToDuplicate.id);

    // const updatedNodes = Array.from(state.nodes);

    // updatedNodes.splice(nodeIndex, 1, unselectActiveNode);
    // state.nodes = updatedNodes;

    state.nodes.push(duplicatedNode);
  },

  nodeRemoved(state: ProductBuilderState, action: PayloadAction<string>) {
    const nodeId = action.payload;
    const nodeIndex = state.nodes.findIndex((node) => node.id === nodeId);
    if (nodeIndex >= 0) {
      state.nodes.splice(nodeIndex, 1);
    }
  },

  nodeUpdated(state: ProductBuilderState, action: PayloadAction<Node>) {
    const updatedNode = action.payload;
    const nodeIndex = state.nodes.findIndex((node) => node.id === updatedNode.id);
    if (nodeIndex >= 0) {
      const updatedNodes = Array.from(state.nodes);
      updatedNodes.splice(nodeIndex, 1, updatedNode);
      state.nodes = updatedNodes;
    }
  },

  nodesAdded(state: ProductBuilderState, action: PayloadAction<Array<Node>>) {
    const nodes = action.payload;
    state.nodes = [...state.nodes, ...nodes];
  },

  nodesChanged(state: ProductBuilderState, action: PayloadAction<Array<NodeChange>>) {
    const changes = action.payload;
    state.nodes = applyNodeChanges(changes, state.nodes);
  },

  proponentsChanged(state: ProductBuilderState, action: PayloadAction<Array<Proponent>>) {
    const proponents = action.payload;
    state.proponents = proponents;
  },

  resetProductBuilderState(state: ProductBuilderState) {
    state.activeNode = null;
    state.autoSave = false;
    state.badNodes = [];
    state.builderIsReady = false;
    state.edges = [];
    state.nodeCategories = [];
    state.nodes = [];
    state.proponents = [];
    state.saving = false;
  },

  toggleMultiselectButton(state: ProductBuilderState, action: PayloadAction<boolean>) {
    state.showMultiselectButton = action.payload;
  },
};
