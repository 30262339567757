import { ObjectOf } from "@common/types/ObjectOf";

export const kpiDictionary: ObjectOf<string> = {
  HIS: "Enquadra no certificado HIS?",
  HIS2: "Enquadra no certificado HIS 2?",
  HMP: "Enquadra no certificado HMP?",
  Person__SalaryPresumed: "Salário estimado",
  Score: "Score de Crédito",
  active_wallet_table_v30_value: "Valor a vencer em até 30 dias",
  cpf_cancelled: "CPF está cancelado?",
  cpf_regular: "CPF está regular?",
  debts_sum: "Soma das restrições",
  docs_uploaded: "Algum documento foi adicionado?",
  estimated_income: "Renda estimada",
  funding_period: "Periodo Financiamento",
  has_assistances: "Possui assistências?",
  has_debts: "Possui restrição?",
  has_partners: "Possui participações societárias?",
  has_prejudice: "Possui prejuízo?",
  has_vehicles: "Possui veículos?",
  highlight__advance_payment_approved: "Está enquadrado no simulador?",
  highlight__desired_income_approval: "Está enquadrado na condição desejada do Simulador?",
  highlight__has_approved_bank_and_building_payment_over_time: "Está enquadrado no fluxo de financiamento e obra?",
  highlight__has_approved_building_payment_over_time: "Está enquadrado no fluxo de obra?",
  income: "Salário estimado",
  income_approval: "A renda está enquadrada no pagamento mensal pretendido?",
  income_commitment: "Comprometimento de renda estimado",
  incoming: "Renda",
  loans_request_3M: "Possui empréstimos nos últimos 3 meses?",
  max_payment: "Capacidade de pagamento mensal estimada",
  rating: "Rating Liquid",
  revenue_presumed: "Faturamento Presumido",
  risk_total_value: "Risco total",
  score: "Score de Crédito",
  score_fraude: "Score de Fraude",
  signed: "Assinatura de Documentos",
  tipoMoradiaFaixa: "Tipo de Moradia do Certificado Núclea",
  value_entry: "Valor Entrada",
  value_total: "Valor Proposta",
};
