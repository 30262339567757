import { Box, Tooltip, TooltipProps } from "@mui/material";
import { LqdTypography } from "..";

type LqdTooltipProps = Omit<TooltipProps, "title"> & {
  hidetooltip?: string;
  overridetitle?: "false" | "true";
  titlebody: JSX.Element | React.ReactNode | string;
  titleheader?: string;
  tooltipColor?: string;
};

export default function LqdTooltip(props: LqdTooltipProps) {
  const { arrow, children, hidetooltip, overridetitle, placement, titlebody, titleheader, tooltipColor } = props;

  const finalPlacement = window.innerWidth > 768 ? placement || "right" : "bottom";

  const hideTooltip = hidetooltip === "true";

  return hideTooltip ? (
    children
  ) : (
    <Tooltip
      arrow={!!arrow}
      placement={finalPlacement}
      PopperProps={
        overridetitle === "true"
          ? {
              sx: {
                ".MuiTooltip-tooltip": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: 2,
                  boxShadow: "0px 8px 24px -3px rgba(16, 24, 40, 0.10)",
                  color: "rgba(160, 174, 192, 1)",
                  fontSize: "10px",
                  maxWidth: "300px",
                  minHeight: "18px",
                  px: "14px",
                  py: 1,
                },
              },
            }
          : {
              sx: {
                ".MuiTooltip-arrow": { color: "rgba(33, 36, 42, 1)" },
                ".MuiTooltip-tooltip": {
                  backgroundColor: tooltipColor || "rgba(33, 36, 42, 1)",
                  borderRadius: 2,
                  maxWidth: "300px",
                  minHeight: "18px",
                  px: 1.5,
                  py: titleheader ? 1.5 : 1,
                },
              },
            }
      }
      title={
        overridetitle === "true" ? (
          titlebody
        ) : (
          <>
            {titleheader ? (
              <LqdTypography sx={{ color: "rgba(255, 255, 255, 1)", pb: "4px" }} textstyle="p2Paragraph">
                {titleheader}
              </LqdTypography>
            ) : null}
            <Box
              sx={{
                color: "rgba(240, 241, 243, 1)",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {titlebody}
            </Box>
          </>
        )
      }
      {...props}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
