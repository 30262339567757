/**
 * Busca a lista de ações globais para um determinado ID de análise.
 *
 * @param {string} analysisId - O ID da análise para a qual recuperar as ações.
 * @returns {Promise<Array<GlobalAction>>} Uma promessa que resolve para um array de ações globais.
 */
import { get } from "@common/utils/apiUtils";
import { GlobalAction } from "../types/ActionType";

export const getActions = (analysisId: string) => get<Array<GlobalAction>>(`/webapp/liquid/action/${analysisId}`);
