import { LqdCircularLoader } from "@/liquid-components/src";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDashboardDetails } from "../api/GetDashboardDetails";
import DashboardFrame from "../components/DashboardFrame";

export default function PlayDetails() {
  const { reportId } = useParams();

  const [APILoading, setAPILoading] = useState<boolean>(false);
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);
  const [iFrameLoading, setIFrameLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleDashboardClick = async () => {
      setAPILoading(true);

      try {
        const { data } = await getDashboardDetails(reportId!);
        const proxiedEmbedUrl = `https://api.dev.liquidpass.co/wallet/reports/${reportId}/proxy?token=${data.token}`;

        setEmbedUrl(proxiedEmbedUrl);
        setIFrameLoading(true);
      } catch (error) {
        console.error("Error loading dashboard:", error);
      } finally {
        setAPILoading(false);
      }
    };

    handleDashboardClick();
  }, [reportId]);

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      {APILoading || iFrameLoading ? (
        <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
          <LqdCircularLoader size={80} />
        </Stack>
      ) : null}
      {embedUrl ? <DashboardFrame embedUrl={embedUrl} onLoad={() => setIFrameLoading(false)} /> : null}
    </Box>
  );
}
